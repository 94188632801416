let menus2 = [
  {
    name: "机构使用帮助",
    nodeId: "1",
    id: 1,
    level: 1,
    disabled: true,
    children: [
      {
        name: "系统操作",
        nodeId: "1-1",
        id: 1,
        piece: 1,
        level: 2,
        show: ["admin"],
      },
      {
        name: "基本话术",
        nodeId: "1-2",
        id: 2,
        level: 2,
        piece: 1,
        show: ["admin"],
      },
      {
        name: "其他",
        nodeId: "1-3",
        id: 3,
        piece: 1,
        level: 2,
        show: ["admin"],
      },
    ],
  },
  {
    name: "教师使用帮助",
    nodeId: "2",
    id: 2,
    level: 1,
    disabled: true,
    children: [
      {
        name: "系统操作",
        nodeId: "2-1",
        id: 1,
        piece: 2,
        level: 2,
        show: ["teach", "admin"],
      },
      {
        name: "基本话术",
        nodeId: "2-2",
        id: 2,
        piece: 2,
        level: 2,
        show: ["teach", "admin"],
      },
      
      {
        name: "其他",
        nodeId: "2-3",
        id: 3,
        piece: 2,
        level: 2,
        show: ["teach", "admin"],
      },
    ],
  },
  {
    name: "学生使用帮助",
    nodeId: "3",
    id: 3,
    level: 1,
    disabled: true,
    children: [
      {
        name: "系统操作",
        nodeId: "3-1",
        id: 1,
        piece: 3,
        level: 2,
        show: ["stu", "admin", "web"],
      },
      {
        name: "其他",
        nodeId: "3-3",
        id: 3,
        piece: 3,
        level: 2,
        show: ["stu", "admin", "web"],
      },
    ],
  },
];
let menus = [
  {
    id: "1",
    type: "产品介绍篇",
    classifList: [
      {
        id: "1-1",
        classif: "产品简介",
        show: [],
      },
      {
        id: "1-2",
        classif: "产品原理",
        show: [],
      },
      {
        id: "1-3",
        classif: "产品功能",
        show: [],
      },
    ],
  },
  {
    id: "2",
    type: "系统操作篇",
    classifList: [
      {
        id: "2-1",
        classif: "后台端",
        show: [],
      },
      {
        id: "2-2",
        classif: "教师端",
        show: ["web"],
      },
      {
        id: "2-3",
        classif: "学生端",
        show: ["web"],
      },
    ],
  },
  {
    id: "3",
    type: "学生使用篇",
    classifList: [
      {
        id: "3-1",
        classif: "校长",
        show: [],
      },
      {
        id: "3-2",
        classif: "教师",
        show: [],
      },
      {
        id: "3-3",
        classif: "学生",
        show: ["web"],
      },
      {
        id: "3-4",
        classif: "家长",
        show: [],
      },
    ],
  },
  {
    id: "4",
    type: "装修物料篇",
    classifList: [
      {
        id: "4-1",
        classif: "资料相关类",
        show: [],
      },
      {
        id: "4-2",
        classif: "装修、物料类",
        show: [],
      },
      {
        id: "4-3",
        classif: "管理资料类",
        show: [],
      },
    ],
  },
];
let problems = [
  {
    classifId: "1-1",
    typeId: "1",
    id: "1-1-1",
    isHot: false,
    title: "晓果智学公司介绍",
    answer:
      "<p>晓果智学是湖南果实智能科技有限公司旗下产品项目之一，湖南果实智能科技有限公司是一家拥有高度自主核心知识产权的高新技术企业。公司立足于全国前沿教育，致力于未来科技教育现代化解决方案和一站式落地运营服务提供商，多项新技术、新产品获得国家专利，并取得了国家教育部、信息产业部等多部门的资质认证。</p> ",
  },
  {
    classifId: "1-1",
    typeId: "1",
    id: "1-1-2",
    isHot: false,
    title: "晓果智学产品介绍？",
    answer:
      "<p>晓果智学是以高级算法为核心的自适应学习引擎，是人工智能AI一对一学习系统。是具有独立知识产权的AI Effect引擎、数学问题模型、语音智能测评等专利，拥有涵盖中小学全年级、全学科的教学资源，知识点短视频3W+个，题库1400W道，与立思辰、跨学网、北教传媒、全品文教等公司合作，汇集北大附中、清华附小、北京四中等百名特级教师顾问团教学经验，融合每一位特级教师教学技巧、教学内容，为自适应学段学生提供新一代AI一对一人工智能学习的系统。让教育更公平、更高效！</p>",
  },
  {
    classifId: "1-1",
    typeId: "1",
    id: "1-1-3",
    isHot: false,
    title: "什么是智能化学习系统？",
    answer:
      "<p>智能化学习系统是通过前期对于学生学习数据的积累，学生使用系统越多有效数据就越多人工智能算法更加精准，通过高效的算法与模型推送更加精准的学习资料、习题及知识图谱，为学生规划个性化学习路径做到查漏补缺和追根溯源，学生通过系统布置的任务进行学习让系统对学生更加了解，如此往复，因此学生使用系统学习的时间和频率越高系统对于学生的规划、学习建议以及学习路径推送的更加精准。</p>",
  },
  {
    classifId: "1-1",
    typeId: "1",
    id: "1-1-4",
    isHot: false,
    title: "什么是晓果智学？",
    answer: `   <p>1）纳米级知识点细分：将知识点细分至纳米级（全面超越课本的知识点细分程度），直接追踪学习“病根”对症下药，全面解决，全方位提升。</p>
            <p>2）个性化知识点图谱：通过不断学习与训练，智能化建立孩子个性化的知识点图谱。</p>
            <p>3）大数据分析：建立个性化知识点图谱数据库，进行大数据分析，找到知识漏洞及薄弱点。</p>
            <p>4）智能化强化训练：根据漏洞智能推需要强化训练的知识点漏洞及薄弱点。</p>
            <p>5）薄弱点循环追踪：不断循环追踪漏洞和薄弱点，直至全面提升。</p>
        `,
  },
  {
    classifId: "1-1",
    typeId: "1",
    id: "1-1-5",
    isHot: false,
    title: "晓果智学独特性优势如何帮助校区解决难题？",
    answer: `<p>OMO模式，助力传统教学转型线上</p>
    <p>晓果智学针对组织能力的团队建设、系统支持、运营管理模式、获客方式、教学、教务体系等一切方面都会提供管家式全方位服务。将教学和服务全部OMO，“线上教学+线下教学+线上服务+线下服务”。</p>
    <h4>1）对于机构：成本更低  效果更好</h4>
    <p>晓果智学AI不再依赖于老师的教学水平和时间，减少了老师数量和成本。小投入，大产出！</p>
    <p> 微信智能测评——招生引流神器，最低成本的获客渠道捕获最精准的目标客户</p>
    <h4>2）对于老师：智能系统+真人助教的教学模式</h4>
    <p>对于每一个学生，均是高效智能辅导：基于大数据分析、人工智能算法、知识图谱的晓果智学AI老师，智能辅导学生；另一个是真人助教教师，线下真人助教老师通过线下互动方式，完成对应的工作。双师教学模式，让教学过程无死角，同时也减轻了老师的负担。</p>
    <h4> 3）对于孩子：精准查漏补缺  省时省力</h4>
    <p>通过大数据+云计算快速找到孩子的知识薄弱点，回到原点学知识、掌握方法。对查出的薄弱知识，系统按三步记忆法，进行补全。</p>`,
  },
  {
    classifId: "1-1",
    typeId: "1",
    id: "1-1-6",
    isHot: false,
    title: "晓果智学如何做到精准诊断学生学习？",
    answer:
      "<p>每个细分知识点的时候都有六种状态：未学、入门、及格、熟悉、熟练、优秀。后台通过机器学习原理，系统会依据不同学生的知识薄弱点、学习习惯和风格，自动匹配相应学习内容。利用大数据算法将这些知识节点组成庞大的网状知识图谱，精准定位学生问题，并对症下药推送学习内容。</p>",
  },
  {
    classifId: "1-1",
    typeId: "1",
    id: "1-1-7",
    isHot: false,
    title: "晓果智学智能系统+真人助教的教学模式是如何实现的？",
    answer: `<p>智能系统：精准定位知识漏洞、智能规划学习路径、自动绘制学生学习画像 、推送针对性学习内容和任务基于大数据分析、人工智能算法、知识图谱的晓果智学AI老师，智能辅导学生。</p>
    <p>真人助教老师：批改线下作业、练习题目的讲解、监督了解学生使用系统情况 、人文关怀和做思想工作、结合系统给学生做学习规划真人助教教师，我们也很清楚辅导某些环节的工作，智能系统难以完成，需要真人助教教师才能完成，线下真人助教老师通过线下互动方式 完成对应的工作。</p>
    <p>在整个辅导过程中，两个老师分别做自己擅长的环节，综合辅导效果比纯线下人工辅导、纯线上辅导效果更好。</p>
    `,
  },
  {
    classifId: "1-1",
    typeId: "1",
    id: "1-1-8",
    isHot: false,
    title: "晓果智学AI老师是怎么实现教学的",
    answer:
      "<p>晓果智能老师，是第二代人工智能自适应学习系统通过人机互动的测评、学习内容推送、练习题测试的过程，晓果智学AI老师会在后台不断地给学生绘制学习画像，确定学生的掌握状态、学习能力等级， 并针对性、动态调整学习路径和推送任务。</p>",
  },
  {
    classifId: "1-1",
    typeId: "1",
    id: "1-1-9",
    isHot: false,
    title: "晓果智学产品亮点？",
    answer: `<p>1）精准查漏补缺、省时省力：通过大数据+云计算快速找到孩子的知识薄弱点，回到原点学知识、掌握方法。对查出的薄弱知识，系统按三步记忆法，进行补全。</p>
        <p>2）个性化教学、增强学生学习自信：以学定教，根据学生自己的基础，该学什么教什么，能学多少学多少。让孩子用“会”解决“不会”，增强学生学习自信，真正做到因材施教。</p>
        <p>3）个性化作业，家长及时掌握孩子学习情况：每个学生端的学习轨迹都会在教师端有如实的反应，便于老师监督，利于与家长反馈，课后打印个性化作业，如实反应学习情况。</p>
        <p>4）闯关学习模式：系统设置闯关和积分，通过游戏化学习设计，让孩子乐在其中。</p>
        <p>5）纳米级知识点细分，学习精耕细作：将240多个主知识切分成了8700多个知识点，利于学生吸收、掌握。</p>`,
  },
  {
    classifId: "1-1",
    typeId: "1",
    id: "1-1-10",
    isHot: false,
    title: "晓果智学的优点是什么，学生为什么要选择晓果智学？",
    answer: `<p>1）教材全同步：小、初、高所有教材版本全同步，覆盖所有学生。</p>
        <p>2）纳米级知识点细分：精准定位学生学习漏洞。</p>
        <p>3）学习由易到难：循序渐进，符合学习规律。</p>
        <p>4）分层教学：按不同基础、不同层次对学生分层教学，适合所有不同基础的学生。</p>
        <p>5）优秀教师视频讲解：由浅入深确保孩子们“吃饱又吃好”。</p>
        <p>6）测、学、练、测、评无限循环，穷尽所有知识漏洞：垂直诊断，无限循环，直至所有漏洞全部找完。</p>
        <p>7）人机互动学习方式：便于计算机读取孩子各项数据，并形成个性化数据库。</p>
        <p>8）题量庞大：1400万道题库自由组合，中高考真题全方位训练。</p>
        <p>9）独创举一反三功能：一道题目做错，自动给出多道知识点相同题目，夯实基础。</p>
        <p>10)学习效果看得见：独创知识点循环追踪系统，建立个性化知识点图谱，不全所有知识漏洞。</p>
        <p>11)真正个性化学习：根据个性化知识点漏洞和薄弱点，智能布置学习任务，真正做到学你索不会，练你所不熟，实现真正个性化学习。</p>
        <p>12)答案解析即使反馈：学得快，记得牢。</p>
        <p>13)答对得学币，商城换奖品：有效激发学生学习兴趣。</p>
        `,
  },
  {
    typeId: "1",
    classifId: "1-2",
    id: "1-2-1",
    isHot: false,
    title: "晓果智学学习系统基本原理",
    answer: `<p>通过获取学生大数据训练样本，构建学科模型、教学方法模型、学生模型等训练模型，结合知识空间理论、信息论技术、逻辑斯蒂回归、遗传算法、贝叶斯网络、概率图模型、贝叶斯知识跟踪利润、神经网络技术、脑科学和脑技术等人工智能算法和技术，采用计算机科学、数据科学、认知科学、教育测量学和学习心理学等深度模拟优秀教师的教学过程及策略用于构建学生需要掌握的内容，从而提升学生的知识水平、错误模型、元认知能力、认知特征、情感特征等。</p>`,
  },
  {
    typeId: "1",
    classifId: "1-2",
    id: "1-2-2",
    isHot: false,
    title: "晓果智学的自适应学习效果采用了哪些技术？",
    answer: `<p>晓果智学运用TensorFlow框架，采用迁移学习、卷积神经网络，构建效果伴学神经学习网络，沿用工程学方法及模拟算法从而达到自适应学习效果。</p>`,
  },
  {
    typeId: "1",
    classifId: "1-2",
    id: "1-2-3",
    isHot: false,
    title: "晓果智学的提分逻辑是什么样的？",
    answer: `<p>1）测：智能检测学习水平，通过人工智能技术全面扫描学科知识点，准确探测知识漏洞并生成可视化报告，360度解读学生知识点掌握情况。</p>
        <p>2）学：个性化学习路径，根据检测后的掌握情况，为学生智能匹配学习内容，量身定制个性化的学习方案，每个学生都有一份属于自己的学习路径。</p>
        <p>3）练：高效智能辅导，基于大数据分析、人工智能算法、知识图谱的晓果智学AI老师,同时通过线下互动方式，帮助学生全面解决知识漏洞。</p>
        <p>4）测：全面检测薄弱点掌握情况，课后综合测试、对比课前测评、综合检测学生知识薄弱点掌握变化情况，以便实时调整下一步学习内容。</p>
        <p>5）辅：纳米级知识点练习，将知识点拆分成纳米级知识点，线下教师针对每一个指示单进行针对辅导和专项学习，填补知识漏洞以构建学生完整的知识网。</p>
        <p>测-学-练-测-辅五个环节无缝对接，形成学习闭环，垂直诊断。</p>`,
  },
  {
    typeId: "1",
    classifId: "1-3",
    id: "1-3-1",
    isHot: false,
    title: "学生学习六大方式",
    answer: `<p>个性化学习、闯关式学习、可视化学习、激励式学习、自助式学习、任务式学习。</p>`,
  },
  {
    typeId: "1",
    classifId: "1-3",
    id: "1-3-2",
    isHot: false,
    title: "线下教师的教学四大步骤",
    answer: `<p>备课-监察-作业-分析。</p>`,
  },
  {
    typeId: "1",
    classifId: "1-3",
    id: "1-3-3",
    isHot: false,
    title: "晓果智学对于招生有哪些帮助？",
    answer: `<p>微信免费测评-招生引流神器。</p>
        <p>机构端、教师端、学生端，每位老师和学生拥有专属的二维码及邀请链接，可通过链接转发到朋友圈或好友进行免费测评，提交后后台将收到测评学生的测评报告及联系方式，可通过报告分析的噱头添加家长微信或引流至门店，转化成交后和查看学生测评源头查找学生来源便于招生裂变提成奖励或返利。</p>
    `,
  },
  {
    typeId: "1",
    classifId: "1-3",
    id: "1-3-4",
    isHot: false,
    title: "晓果智学机构后台的主要功能有哪些？",
    answer: `<p>机构后台主要功能：添加教务账号、添加教师账号、添加学生账号、设置学生课程、设置教师课表、机构微信测评二维码及学生测评报告、商城管理。</p>`,
  },
  {
    typeId: "1",
    classifId: "1-3",
    id: "1-3-5",
    isHot: false,
    title: "晓果智学教师端的主要功能有哪些？",
    answer: `<p>教师备课（磨课、刷题）、布置任务（学习和作业）、监课室（学生行为监察）、知识点加解锁、学生学情分析报告、微信免费测评。</p>`,
  },
  {
    typeId: "1",
    classifId: "1-3",
    id: "1-3-6",
    isHot: false,
    title: "晓果智学学生端的主要功能有哪些？",
    answer: `<p>学生入学测试、系统布置每日任务学习、知识点学习、错题库举一反三练习、自我提升练习（模拟考试、自主练习、智能推荐）、学情分析报告、学币商城、微信二维码测评。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-1",
    isHot: true,
    title: "谷歌浏览器在哪里下载？",
    answer: `<p>登录晓果智学官网www.xiaoguoai.cn进入首页，点击上方菜单栏【浏览器下载】，苹果电脑点击【Mac版浏览器下载】，微软系统点击【Windows版浏览器下载】即可。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-2",
    isHot: true,
    title: "机构后台怎么开通？",
    answer: `<p>加盟校区填写加盟信息登记表，并回传至企业微信服务群，并@陈萍萍开通即可。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-3",
    isHot: false,
    title: "为什么后台账号登录不了？",
    answer: `<p>区别登录的是1.0系统后台还是2.0系统后台，1.0系统后台账号为机构名称首字母小写缩写，2.0系统后台账号为机构名称首字母小写缩写+101001后缀。密码统一为初始密码123456.</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-4",
    isHot: true,
    title: "为什么登录账号显示账号不存在或账号密码错误？",
    answer: `<p>1、网址登录错误：如登录机构后台或教务账号网址为www.xiaoguoai.cn/admin/，如登录学生端或教师端账号网址为www.xiaoguoai.cn，教师端显示“教师登录”字样，学生端显示“学生登录”字样。
    2、账号输入错误。
    3、密码被修改：学生或教师及机构后台多人使用或密码修改后忘记密码。机构后台
    联系总部初始化密码，教务、教师、学生联系机构后台管理员初始化密码，初始化后密码为123456。
    4、手机号码错误或未验证：教务、教师手机号码错误首次登录需要输入验证码登录，
    如手机号码录入错误则无法登录，学生账号默认8天后手机短信验证码验证如未验证或手机号码错误将会显示账号不存在或账号密码错误，需联系机构后台管理员修改手机号码。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-5",
    isHot: false,
    title: "账号后面的数字代表什么意思？",
    answer: `<p>系统账号由系统默认生成，101001中101001为校区代码1,101001为机构后台账户编号。201001中201001为教务管理账号代码，201001为教务账户编号。301001中301001为教师管理账号代码，301001为教师账户编号。401001中401001为学生账号代码，401001为学生账户编号。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-6",
    isHot: true,
    title: "为什么学生提交了微信测评后台没有收到测评报告？",
    answer: `<p>学生微信免费测评后必须填写正确手机号码并填写收到的短信验证码提交后方可在机构后台收到并生成测评报告。如未提交或未输入手机号码或未填写短信验证码等直接退出则机构后台无法收到测评报告。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-7",
    isHot: true,
    title: "测评报告怎么生成PDF文件？",
    answer: `<p>机构后台账户进入【邀请管理】菜单，点击【邀请列表】选项，点击对应学生右侧【查看报告】按钮，点击右侧【详细报告】按钮，当评测报告出来后点击左上角【打印报告】按钮，系统跳转至打印页面后在【目标打印机】选项中点击右侧三角符号，选中另【存为PDF】，点击保存，选择保存路径即可。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-8",
    isHot: true,
    title: "刚开课程系统就显示余额不足？",
    answer: `<p>进入后台管理中【资金明细】菜单中查看资金扣款明细及所开通课程，并进入【课程管理】核对开课时间及课程到期时间。课程扣款根据所开月份进行扣款，如多开课程时长需要撤销需提交申请至总部产品部，三个工作日后方可撤销，如账户余额不足请先充值，已开错课程撤销后余额将会返还至账户。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-9",
    isHot: true,
    title: "开通教师及学生账号后怎么进入教师端和学生端进入学习？",
    answer: `<p>开通账号后，进入晓果智学官网www.xiaoguoai.cn进入首页，点击【登录】按钮选择我是老师/我是学生，从机构后台复制教师或学生账号，输入密码即可登入教师端或学生端。初始密码均为123456。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-10",
    isHot: true,
    title: "开通了多个学生账号，为什么系统没有显示？",
    answer: `<p>在机构后台【学生管理】及【课程管理】中查看学生账号及课程的开始时间和结束时间，如设置8天体验或开始时间未到或已过结束日期将会出现此情况。可通过筛选已结束课程、未开始课程等进行筛选课程。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-11",
    isHot: true,
    title: "开通学生账号时无法选择课程和教材版本？",
    answer: `<p>请在服务群联系陈萍萍老师开通权限，如未提交准确教材版本信息请补填加盟信息登记表至陈老师处登记备案。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-12",
    isHot: false,
    title:
      "如果学生刚考完五年级，准备升六年级，目前课程还是五年级下册的，可以开通六年级课程且两个年级课程并存吗？",
    answer: `<p>可以的！系统扣款规则：跨学段、跨学科、跨版本。默认为小学、初中、高中三个学段。如已开通小学五年级下册数学需要再开通同一版本四、六年级数学不会进行收费，后台开通后学生端刷新即可看到增加的课程。如已开通人教版小学五年级下册数学需要再开通七年级数学或者五年级英语或北师大版小学五年级下册数学则需要进行扣款计费。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-13",
    isHot: true,
    title: "添加了学生为什么看不到课程？",
    answer: `<p>1、检查是否有将学生添加到课程或有没有开通对应课程。</p>
        <p>2、检查课程开始时间和结束时间，如时间延后则需要等到开始时间才能看到，如课程设置时间较短课程已结束则需要筛选已结束课程查看，如需开通请重新添加课程。</p>
        <p>3、开通了学生账号和课程教师端看不到：在机构后台学生管理中查看授课老师名称与所登录教师账号是否一致。如课程未开始或已结束则需要再教师端筛选未开始课程或已结束课程方可查看课程。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-14",
    isHot: true,
    title:
      "开通课程时为什么显示的费用每个月的都在变，开通六个月比五个月还便宜？",
    answer: `<p>系统扣费规则为半年卡、月卡和免费八天三种。</p>
        <p>1）免费八天：当在学生管理中添加个人课程选择免费八天则扣款1元。</p>
        <p>2）月卡：当新建公共课程后按照课程设置的时间加入学生后将显示扣款金额，如小学扣款规则为除六个月以外月份将按照每月150元标准进行累加，如5个月为750元，7个月为1050元，12个月为18000元。</p>
        <p>3）半年卡：当新建公共课程后如设置课程时长为6个月如小学课程则显示扣款300元，半年卡只可选择一次，半年内学生只可学一册书，如学生在下一学期或下一学年需继续学习的需要重新开设对应课程以6个月为单位进行设置，扣款将以半年卡进行结算。
        注：各级别加盟校区或代理商可通过所签署的加盟代理合同查看扣款标准！</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-15",
    isHot: false,
    title: "在课程管理新建课程后添加学生为什么学生还是看不到课程？",
    answer: `<p>新建课程后学生看不到课程的原因：</p>
        <p>1）查看是否将学生添加进入课程：</p>
        <p>机构后台或教务账号中进入课程管理点击对应课程右侧“查看学生”后滑动至最下方查看该课程中是否显示对应学生。</p>
        <p>在新建课程后新增学生时或出现以上已添加学生但课程中未显示学生名称时，进入”课程管理“中点击对应课程右侧”查看学生“按钮点击左下方“添加学生”按钮，勾选学生后点击中间跳转按钮后学生姓名将显示在右侧选择框中，再次勾选学生姓名后点击确定按钮即可完成添加学生。</p>
        <p>2）查看课程是否开始：</p>
        <p>机构后台或教务账号中进入课程管理查看对应课程开课时间是否开始，如开课时间在查询时间之后则需要等到开课之日方可进入课程。如开课日期时间后置较长（一周以上）建议重新开通课程设置时注意开课时间。如已经开始扣费提交可将情况反馈至服务群并@栾老师进行处理，三个工作日后进行结算返款。</p>
        <p>3）查看是否学生账号是否输错：</p>
        <p>机构后台或教务账号中进入学生管理中查看学生账号与所输入账号是否错误，或查看对应学生是否开通多个账号以至于登录的账号与开设课程的学生账号不符导致学生登录后没有课程显示。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-1",
    id: "2-1-16",
    isHot: false,
    title:
      "为什么在班级管理中把课程移除了教师端和学生端还是会显示已经移除的课程？",
    answer: `<p>首先需要区分课程管理和班级管理！课程管理为教师和学生之间的纽带，只有教师和学生在同一个课程教师才能管理学生，才能看到学生的数据。而班级管理只是在同一老师管理不同年级不同科目或多个班级时便于教师在监课室监控的一个功能工具，班级管理实质上和学生没有半毛钱关系。</p>
        <p>方便区分可以将班级管理理解为班级（组）管理。课程管理和班级（组）管理是先后关系不是隶属关系，老师有了课程才能够有班级而不是有班级才有课程，因此添加课程后才能将课程放进班级，从班级组中移除课程并不能取消课程，因此在班级管理中将课程移除教师端和学生端使用并不会产生影响，唯一会影响的是教师在监课室中这些课程不再以班级（组）的形式出现。</p>
    `,
  },
  {
    typeId: "2",
    classifId: "2-2",
    id: "2-2-1",
    isHot: true,
    title: "为什么登录账号显示账号不存在或账号密码错误？",
    answer: `<p>1、网址登录错误：如登录机构后台或教务账号网址为www.xiaoguoai.cn/admin/，如登录学生端或教师端账号网址为www.xiaoguoai.cn，教师端显示“教师登录”字样，学生端显示“学生登录”字样。</p>
        <p>2、账号输入错误：账号为机构名称小写首字母缩写+数字后缀，1为校区（101001）、2为教务（201001）、3为教师（301001）、4为学生（401001），后三位001为学生编号。</p>
        <p>3、密码被修改：学生或教师及机构后台多人使用或密码修改后忘记密码。机构后台联系总部初始化密码，教务、教师、学生联系机构后台管理员初始化密码，初始化后密码为123456。</p>
        <p>4、手机号码错误或未验证：教务、教师手机号码错误首次登录需要输入验证码登录，如手机号码录入错误则无法登录，学生账号默认8天后手机短信验证码验证如未验证或手机号码错误将会显示账号不存在或账号密码错误，需联系机构后台管理员修改手机号码。</p>
    `,
  },
  {
    typeId: "2",
    classifId: "2-2",
    id: "2-2-2",
    isHot: false,
    title: "教师备课点了全体加锁或者全体解锁要在哪里可以看到？",
    answer: `<p>在教师备课中如点击全体加锁或全体解锁只会在备课室中上方显示“全体加锁成功”或“全体解锁成功”字样，无法查看具体哪一个学生或哪一个章节是否加解锁，需进入班级关机中点击对应学生进入学生管理界面查看知识点加解锁状态。</p>
        <p>如针对个别学生之前对某一章节是否加锁，在学生需要学习对应章节时在教师端备课室中直接点击对应章节的全体解锁按钮，即整个课程下所有学生对应的知识点都将进行解锁，反之点击全体加锁则全体学生对应章节加锁无法进入该章节知识点学习。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-2",
    id: "2-2-3",
    isHot: true,
    title: "教师端作业怎么布置？",
    answer: `<p>登录教师端点击左侧菜单栏【作业管理】点击右侧【作业布置】按钮，系统将自动跳转至【查看历史卷】栏，点击右上角【发布新试卷】或【智能组卷】即可。</p>
    <p>发布新试卷：教师手动布置试卷，需要一道一道题的手动加入试卷。</p>
    <p> 智能组卷：分为个性化试卷和统一试卷。选择学科、版本、年级后可进入编辑。
        <p>个性试卷：选择知识点后可在右侧根据需要填写和选择，点击确定后试卷将直接发布。</p>
        <p>统一试卷：选择知识点后可在右侧根据需要填写和选择，（难度选择中如选择自选则五个难度都可进行勾选）点击确定后将跳转至组卷管理修改试卷具体内容。</p> 
    </p>
    <p>试卷发布详细操作详见晓果资料库【1.1.5晓果智学系统2.0操作指南】文件。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-2",
    id: "2-2-4",
    isHot: false,
    title: "教师端的学习任务怎么布置？",
    answer: `<p>登录教师端点击左侧菜单栏【班级管理】选中对应的课程点击右侧【进入班级】按钮，点击【备课】按钮，选中对应知识点后在点击该知识点最右侧【＞】符号再点击【布置任务】按钮，填写和选择内容即可发布对应知识点学习任务给指定学生。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-2",
    id: "2-2-5",
    isHot: false,
    title: "教师端备课室备课的题目和课件怎么推送？",
    answer: `<p>当老师在备课时将文件或题目加入备课室后可通过教师端监课室进行推送。</p>
        <p>登录教师端点击左上角【进入监课室】按钮，点击备好课的题目或课件所在的班级或课程后在当前在线人数XX人后将显示【发布备课题目】和【发布备课文件】两个按钮。当学生学习到对应的已经备课的知识点时就可以直接推送题目或课件了。</p>
        <p>注：1）我的监课室为什么没有【发布备课题目】和【发布备课文件】按钮？</p>
        <p>前提是必须选择对应的班级和课程，如果多个班级或课程没有选择对应课程或班级的情况下将不会显示按钮。</p>
        <p>2）为什么监课室【发布备课题目】和【发布备课文件】按钮是灰色的点不了？</p>
        <p>当前课程或班级中如没有任何一个学生上线按钮显示为灰色，无法点击推送。因为备课题目和文件时实时推送的，因此只有上线的学生才可以推送，没有上线的学生无法推送。</p>
    `,
  },
  {
    typeId: "2",
    classifId: "2-2",
    id: "2-2-6",
    isHot: true,
    title: "为什么布置试卷的时候要一个个的选题目可以自动生成吗？",
    answer: `<p>可以的！当教师选择【发布新试卷】时试卷题目是需要一道一道题目添加的，如需自动生成可选择【智能组卷】选项，个性试卷只需选择知识点即可自动发布，统一试卷除自动匹配试题外将跳转到试卷设置，可进行修改试题。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-2",
    id: "2-2-7",
    isHot: false,
    title: "试卷发布错了可以撤回吗？",
    answer: `<p>不可以！作业一经发出不可撤回不可删除，如题目出错或发错学生可线下通知忽略该份试卷即可。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-2",
    id: "2-2-8",
    isHot: false,
    title:
      "在发布个性试卷时选择题型只有总题数，系统自动分配选择题和填空题比例，要怎么样才能自己设置呢?",
    answer: `<p>此类情况主要出现在【智能组卷】中的个性试卷，如需自己进行调整可选择使用统一试卷出题，选择完知识点、题型、难度和数量后将跳转至试卷设置界面可手动调整题型及难度占比。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-1",
    isHot: false,
    title: "学生微信免费测评只能在手机上操作吗？",
    answer: `<p>不是！微信测评和学生学习不要混淆。
    原因：学生学习必须通过电脑使用谷歌浏览器学习的原因是其他浏览器的不兼容会导致系统界面显示不全、视频学习只有声音没有画面或只有画面没有声音、不断提示系统超时等。
   微信免费测评是通过微信链接进行转发的一个链接形式，因此只要能够登录微信的设备能够有完整的显示屏幕都是可以进行免费测评的（电话手表除外，虽然由于微信功能但是屏幕较小无法将题目题干显示完全）。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-2",
    isHot: false,
    title: "微信免费测评时提交的时候老是显示验证码错误是什么情况？",
    answer: `<p>1）首先确认提交时验证码输入是否超时，验证码有效期为5分钟，超时作废。</p>
        <p>2）其次验证码为六位数密码，确认是否是复制粘贴时文字或空格同时被复制没有删除，建议直接输入数字。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-3",
    isHot: false,
    title: "为什么登录账号显示账号不存在或账号密码错误？",
    answer: `<p>1、网址登录错误：如登录机构后台或教务账号网址为www.xiaoguoai.cn/admin/，如登录学生端或教师端账号网址为www.xiaoguoai.cn，教师端显示“教师登录”字样，学生端显示“学生登录”字样。</p>
        <p>2、账号输入错误：账号为机构名称小写首字母缩写+数字后缀，1为校区（101001）、2为教务（201001）、3为教师（301001）、4为学生（401001），后三位001为学生编号。</p>
        <p>3、密码被修改：学生或教师及机构后台多人使用或密码修改后忘记密码。机构后台联系总部初始化密码，教务、教师、学生联系机构后台管理员初始化密码，初始化后密码为123456。</p>
        <p>4、手机号码错误或未验证：教务、教师手机号码错误首次登录需要输入验证码登录，如手机号码录入错误则无法登录，学生账号默认8天后手机短信验证码验证如未验证或手机号码错误将会显示账号不存在或账号密码错误，需联系机构后台管理员修改手机号码。</p>
    `,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-4",
    isHot: false,
    title: "学生端的电脑客户端在哪里下载怎么用？",
    answer: `<p>在浏览器中输入晓果智学官网网址www.xiaoguoai.cn进入官网后，点击上方菜单栏中“客户端下载”按钮后点击“Windows版学生客户端下载”（目前仅支持微软系统，苹果系统电脑无法使用）即可下载，下载时将其存储路径选择桌面，下载完成后点击安装并运行即可。客户端与网页同步，学生只需输入自己的账号及密码即可登录，端口默认为收缩页面可点击右上角窗口放大按钮放大至全屏。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-5",
    isHot: true,
    title: "为什么学生账号之前可以登录现在登录不了了？",
    answer: `<p>学生账号默认为使用系统8天后需要手机短信验证，如手机号码填写错误则无法验证也就无法登录。录入学生信息是请使用真实有效的手机号码，否则只有8天有效期。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-6",
    isHot: false,
    title:
      "学生做填空题、应用题或者证明题时答案是对的，为什么显示错误？看着没有区别呀？",
    answer: `<p>1）学生输入错误：除选择题外，其他题型都需要学生使用系统自带键盘（公式编辑器）输入答案或解答，当点击对应答案输入框公式编辑器将自动跳出，如6÷3=2这项列式中除号填写成6/3=2则为错误，/在普通文档中即表示除号也表示分数，但学生答题时必须使用严谨的数学符号，因此只要需要输入的数字、符号、汉字等都要统一使用公式编辑器输入。</p>
    <p> 2）学生使用键盘输入：学生使用键盘输入时所显示的字符与系统所录入的数学字符有所差别因此系统识别不出来，即使答对也可能显示错误。而且键盘只能输入+、-、=、（）等基础符号，如≠、×、÷、㎡、≈、≤、≦等以及根号、分式、立方、化学方程式等其他特殊符号均无法使用普通键盘输入，因此需要学生填入的选项必须使用系统自带的公式编辑器输入解答过程或答案，避免因输入错误而导致正确率下降影响学生的学习积极性和自信心。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-7",
    isHot: true,
    title: "学生答题时公式编辑器要怎么使用？",
    answer: `<p>公式编辑器怎么使用的问题可以参考以下步骤：</p>
    <p>1）首先点击需要输入答案的对话框系统将显示出公式编辑器。</p>
    <p>2）根据所需要输入的答案或步骤按照从左往右的方法进行填写。</p>
    <p>3）填写完毕后点击公式编辑器右上角×号关闭，提交答案即可。</p>
    <p>各个公式如何使用可参照【晓果资料库】→【1、管理物料】→【1.1晓果智学2.0系统操作】→【1.1.8晓果智学公式编辑器使用输入说明2020.8.25-PDF】文件。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-8",
    isHot: false,
    title: "学生答题为什么一定要用公式编辑器？不可以键盘和编辑器兼容吗？",
    answer: `<p>不可以，必须使用公式编辑器进行录入。原因：答案具有唯一性，题库录入使用的是公式编辑器，因此系统只认公式编辑器输入的答案。就比如别人家的钥匙开不了你家的门锁是一个道理，因此不可以使用键盘进行录入。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-9",
    isHot: true,
    title: "系统题库超纲或答案错误或题干错误？",
    answer: `<p>点击该题右上角反馈按钮提交至后台，后台收到后将会在五分钟内进行处理。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-10",
    isHot: false,
    title: "系统中题库步骤或分析错误、书写错误？",
    answer: `<p>题库由教研部老师人工做题书写步骤并上传，如发现错误点击该题右上角反馈按钮提交至后台，收到后将会在五分钟内进行纠正。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-11",
    isHot: false,
    title: "做题的时候明明选的是正确答案，但是系统显示是错误的？",
    answer: `<p>点击该题右上角反馈按钮提交至后台，后台收到后将会进入题库进行审核，并修复。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-12",
    isHot: true,
    title: "学生端进入薄弱知识点、课前测试或随堂练习显示暂无题目？",
    answer: `<p>1、检查是否使用谷歌浏览器，如使用其他浏览器请更换至谷歌浏览器登录学习。</p>
        <p>2、检查网络是否有问题，在浏览器设置中点击清除缓存或重启路由器或重新插入网线，点击刷新页面即可。</p>
    `,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-13",
    isHot: true,
    title: "学生没有学过的知识点该知识点进度条显示已学50%是怎么回事？",
    answer: `<p>系统通过知识图谱关联，对知识点进行追根溯源。当未学习知识点是已学知识点的基础或延伸知识点时未学知识点同样显示已学的进度。</p>`,
  },
  {
    typeId: "2",
    classifId: "2-3",
    id: "2-3-14",
    isHot: true,
    title: "薄弱知识点和课前测试显示超时了，不可以再做怎么办？",
    answer: `<p>薄弱知识点、课前测试为系统每天自动推荐作业内容，在今日任务中点击后将会显示在课后作业见面。如显示【该作业已超时】请点击页面右侧【今日任务】按钮进行系统今日作业推送答题。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-1",
    id: "3-1-1",
    isHot: false,
    title: "现在教育为什么要采取人工智能",
    answer: `<p>我们现在的购物，支付都采取了线上的方式，采取线上人工智能教学也是大势所趋，并且因为疫情影响，为了保证教学进度，线上AI教学也逐渐体现出它的作用，并且采取人工智能，能够弥补线下的很多教学问题，如个性化学习方案制定，学情报告生成等，为学生提分，也给教师省力，更多的时间在于给引导学生上面。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-1",
    id: "3-1-2",
    isHot: false,
    title: "人工智能为什么要选择晓果智学？",
    answer: `<p>晓果智学是一家有教育情怀的公司，目标就是实现教育的公平化，并且，我们除了提供优秀的系统外，还会予以校区各类支持及各类服务。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-1",
    id: "3-1-3",
    isHot: false,
    title: "给予的支持有哪些？",
    answer: `<p>我们予以的支持会根据时间以及个校区的需求不断进行优化，并不是固定的，比如有装修补助，PC硬件支持等支持，详细需要查看当时的招商政策。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-1",
    id: "3-1-4",
    isHot: false,
    title: "晓果给我们提供怎么样的服务？",
    answer: `<p>1）常规服务：
    <p> 系统操作客服支持（针对系统操作的培训及后期问题解答）;</p>
    <p> 师训支持（我公司师训支持一共有两种形式，一种是会定期开展师训，会通过电话，微信等通道通知给各位，有需要的可以参加。第二种形式是各位可以填写《培训申请表》回传给我公司，我公司会根据申请内容，定制师训）;</p>
    <p> 其他支持（我公司还会提供其他的相关支持，比如装修补助，教学知识科普，学业规划等服务，会根据各机构需求及现状不断优化）</p></p>
        <p>2）招生服务：由公司合作的第三方来对机构进行招生服务，具体政策可联系效果工作人员。</p>
        <p>3）物料定制服务：由公司合作的第三方来对机构进行物料定制服务，具体政策可联系效果工作人员。</p>
        <p>4）PC监管服务：由公司合作的第三方来对机构进行PC监管系统服务，具体政策可联系效果工作人员。</p>
        <p>5）PC硬件服务：我公司有专门合作的电脑硬件供应商，提供7天无理由退货，30天包换，365天整机保修，以换代修，终身软件维护，商品包邮等服务。</p>
    `,
  },
  {
    typeId: "3",
    classifId: "3-1",
    id: "3-1-5",
    isHot: false,
    title: "晓果为什么可以让培训学校生源快速扩大？",
    answer: `<p>晓果的优势：1.高效率，2.低成本，3.无需专业老师三个因素，会让你的学习借助移动互联网快速扩大影响。很多学校一个简单的微信营销方案，一天就有几百人报名。
    </p>`,
  },
  {
    typeId: "3",
    classifId: "3-1",
    id: "3-1-6",
    isHot: false,
    title: "为什么说晓果可以让培训学校拓展培训项目，实现快速扩张？",
    answer: `<p>无低成本，好效果，高利润，无需专业老师，会让培训学校找到飞一样的感觉，快速扩张。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-1",
    id: "3-1-7",
    isHot: false,
    title: "为什么晓果智学能让你不再为师资犯愁？",
    answer: `<p>师资问题，一直是困恼培训学校发展的主要问题。请名师上课，受制于名师；安排其他老师上课，又无法保证教学效果；好不容易培养出1-2个好点的老师，其他学校多出10块钱就可以把你辛辛苦苦培养出来的老师撬走。反之，用晓果开班，完全弱化了师资的教学作用。甚至比名师还更容易出效果，更容易保证教学质量。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-1",
    isHot: false,
    title: "晓果智学是如何实现个性化教学的？",
    answer: `<p>效果伴学是通过追根溯源，找到学生的根本性的问题，比如是上个年级残留的知识点没有掌握的知识点。老师根据学生的学情，学习习惯，来规划学生的学习。在晓果系统中，老师能够按照学员的不同情况，布置针对性不同，难度不同的作业，并非像学校里大家的作业是一样的，我们除了对学生的作业布置进行个性化规划以外，我们的老师（学管师）也会根据学生的性格进行不同方式的教学，采取不同的沟通策略。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-2",
    isHot: false,
    title: "晓果智学有哪些激励学生学习的手段？",
    answer: `<p>晓果激励学生的手段分为两大类，一种是学币商城，学生每次在线学习，通过各种学习方式都会累计获得学币，学币可以在我们的学币商城内购买学生自己喜欢的商品，有的学员就是因为很喜欢某个商品，不断努力学习累计学币，换到自己心仪的商品。第二种就是精神上的激励，传统的学习学生是无法清除认知到自己的知识点学习情况的，晓果智学系统能可视化地暂时学生学习情况，让学生认知，进过努力，我已经进步了，激发学生的学习热情。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-3",
    isHot: false,
    title: "晓果智学对我们教师而言有什么便捷性？",
    answer: `<p>首先，教师之前很大一部分精力需要耗费在授课以及作业批改上面，使用我们的系统后，知识点的讲解是由我们的系统来完成的，学生在系统上做完题后，系统会自动批改并且生成报告，老师的工作中有更多的时间去关注学生，给学生定制个性化的学习方案，帮助学生解决学习问题，培养学生好得学习习惯。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-4",
    isHot: false,
    title: "晓果智学的教学流程是怎么样的？",
    answer: `<p>我们的基本教学流程是测，学，练测，辅。测包含前测与后测。前测包含智能入学测试，教师布置测试；学包含智能导航学习，老师规划学习，学生自主学习；练包含知识点学后练习，系统智能复习，预习，错题清理练习，老师发布作业练习；后测包含教师布置测试，智能效果测试；最后就是辅导，一定是在最后的，包含监督管理，个性化答疑，路径规划，激励鼓励，教学反馈，布置任务。以上流程缺一不可，并且需要按序进行。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-5",
    isHot: false,
    title: "晓果智学是代替老师来教学生吗？",
    answer: `<p>我们系统采取的是双师系统，我们的系统主要是一个辅助性的工具，并不存在代替老师来教学，我们线上的老师进行知识点讲解，并且进行做题。而线下的老师主要就是规划学员学习方案，更加侧重于学管师工作，对于教学技能要求相对较低。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-6",
    isHot: false,
    title: "有什么方法能够提升学员的做题速度？",
    answer: `<p>学生掌握知识点或者平时在智习室学习主要用于提升做题的正确率，提升做题速度可以采取老师布置作业卷，有时间限制，也可以学生在自我提升里进行模拟考试，也有时间限制，要求做题正确率与速度并存才能得到好成绩。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-7",
    isHot: true,
    title: "进度条显示学生知识点掌握已经优秀了，为什么考试成绩还不行？",
    answer: `<p>进度条表示的是学生在学习过程中知识点掌握的情况，只是一个暂时的状态，比如今天已经经过学习掌握程度到达了优秀，经过一段时间，学生没有进行学习，已经把这个知识点忘记了，系统还是显示进度条是优秀。需要学生频繁做题完成任务，经过学情中心分析才能说明学习情况，进度条只能说明暂时的学习状态。</p>`,
  },
  {
    typeId: "8",
    classifId: "3-2",
    id: "3-2-1",
    isHot: false,
    title: "可以给学员布置回家作业吗？",
    answer: `<p>可以的，如果学生自己家里有电脑，完全可以让学生在家里操作系统进行学习，老师也可以布置线下的作业，打印出来，给学生带回家去做。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-9",
    isHot: false,
    title: "平时老师在上课期间是不是只要关注监课室就好了？",
    answer: `<p>老师需要关注监课室，但是不是只要关注监课室，建议学生在学习过程中，老师还是需要在教师里巡视，关注学生的学习状态，有分神的学员及时进行引导。监课室可以老师在远程，或者不方便走动（如刚好需要使用系统进行备课）使用。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-10",
    isHot: true,
    title: "体验课应该怎么安排？ ",
    answer: `<p>体验课流程具体可在资料库教师培训文件下进行下载查看。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-11",
    isHot: false,
    title: "晓果系统怎么清理错题？",
    answer: `<p>当学生出现错题时，需要设置错题标签，设置知识点重要程度，出错原因。错题将会自动进入错题库，今日错题在12小时内不需要清理，并且会显示解析和答案。12小时后需要清理，若还是出错，那还是要设置标签，并且今日无法清理，需要12小时后清理。或者花费50学币再试一次（一日一题仅一次）若清理过程中，做对了，还暂时不能清理，需要进行举一反三完成类似知识点的其他题目才能清理（防止学生猜答案或者硬记答案）</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-12",
    isHot: false,
    title: "学币商城放什么奖品和商品比较合适？",
    answer: `<p>学币商城放置的商品可以放我们机构的课程券，也可以放置带有机构LOGO的礼品，也可以通过学生去了解他们需要什么，让他们有目标感。不建议机构备货，只需要学生兑换了后与学生沟通，商品总部发放需要一点时间，机构用这个时间西行去购买即可。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-13",
    isHot: false,
    title: "为什么学生信息表要提前填写？填写哪些内容？",
    answer: `<p>学生来机构需要提前给学生开好帐号，填写好学生姓名，了解的渠道，体验课日期，学生账号。因为学生家长来机构，看到已经填写好的这些内容，会节省时间，并且会让家长感觉服务很到位，有利于转化。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-14",
    isHot: false,
    title: "体验课中学员啥时候上机比较合适？",
    answer: `<p>学员有明确的学习目标，有学习动力的时候，如果学生不想学习，或者是被家长逼迫来学习的，需要我们的老师进行沟通，了解原因，引导学生去学习。如果引导不好，需要让学员回家去调整好学习状态后再来学习。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-17",
    isHot: false,
    title: "第一次的测评报告为什么要保留？",
    answer: `<p>学生第一次进行测评是在自我提升里，测评完后，学情中心会出现报告，需要截图保留，因为后期的学习会把数据更新至最新状态。保留第一次的测评报告是为了后期转化过程中，进行对比，让家长和学生了解到学习的效果。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-18",
    isHot: false,
    title: "微信群里有些家长产生质疑，有些家长发一些不利于我们的内容怎么办？",
    answer: `<p>针对这样的家长，老师需要及时处理，把家长踢出群，并且建立新群，建立Vvip群（机构多名教师和一位家长组成的群，多名教师进行服务，可以是校长，教务主任等）需要消除家长的质疑后，才能拉进大群。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-19",
    isHot: false,
    title: "微信宣传发什么好？",
    answer: `<p>可以发学习情况的反馈，学习督促内容，表扬内容，发素材（学生学习状态，教学知识，品牌宣传内容，学习案例等）</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-20",
    isHot: false,
    title: "微信宣传素材怎么获取？",
    answer: `<p>作图可以使用PPT，创客贴，图怪兽，美图秀秀等作图及美图工具；视频可以使用EV录屏，OBS，剪映，格式工厂等录屏，视频处理软件；内容可以通过微信，搜狗微信搜索，抖音，百度，以及自己的相机进行获取。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-21",
    isHot: false,
    title: "微信里我们督促学员学习，家长也不理会，怎么办？",
    answer: `<p>家长不理会是家长的责任，而发督促内容是我们老师的责任，是两回事，不能因为学生不学习，家长也不管，我们老师就放弃了。因为后期若是家长质疑效果，完全可以说我们机构的工作很到位，需要家长多配合工作。这些持续发送的督促内容就是证据。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-2",
    id: "3-2-22",
    isHot: false,
    title: "微信朋友圈每天发几条去宣传比较合适？",
    answer: `<p>建议每天最多6条，并且不要同一时间进行刷屏，可以进行朋友圈互动，比如第几个点赞可以获得XX礼品。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-3",
    id: "3-3-1",
    isHot: true,
    title: "想要学习的时候发现课程已到期，怎么回事？",
    answer: `<p>查看是否重复开通该课程，后面开的课程会把先前的覆盖。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-3",
    id: "3-3-2",
    isHot: false,
    title: "我做对题目了，为什么判错？",
    answer: `<p>可能是系统原因，可以点击右上角反馈给总部，进行调整。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-3",
    id: "3-3-3",
    isHot: false,
    title: "那些公式，符号怎么插入？",
    answer: `<p>选择题不用填写公式和符号。填空题点击需要填写的空格，页面下方会出现公式编辑器，选择进行点击插入即可，不要用键盘上打出符号进行输入，会导致作答错误。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-3",
    id: "3-3-4",
    isHot: false,
    title: "老师布置的作业可以晚点再完成吗？",
    answer: `<p>老师布置的作业一定要强调，不能拖延，不能给学员养成拖延的习惯。我们老师布置的作业是有时间限制的，系统的任务也有时效性，过了时间，就会过期。就浪费了学习的机会。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-3",
    id: "3-3-5",
    isHot: false,
    title: "系统推荐的任务一定要完成吗？",
    answer: `<p>需要完成，这是系统通过自适应，人工智能，艾宾浩斯记忆曲线推送的任务，非常有针对性，让学生事半功倍，并且会推送错题清理任务，及时清理错题，消除薄弱点。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-3",
    id: "3-3-6",
    isHot: false,
    title: "突然页面无法显示了，死机了，怎么办？",
    answer: `<p>不要进行任何操作，直接举手告知现场老师，不要进行任何操作，包括硬件操作，不可进行强行关机，拔线等操作。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-1",
    isHot: false,
    title: "采取使用电脑网络教学，孩子上网娱乐怎么办？",
    answer: `<p>我们现场有老师进行巡视进行监管的，而且我们的电脑能配备还原系统，学生是不能进行娱乐活动的，同时，老师通过监课室能实时了解学员的动向，学生长时间未进行学习老师是能够看得到的。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-2",
    isHot: false,
    title: "会不会对孩子的视力产生影响？",
    answer: `<p>我们现在主要面对的是四年级以上的学生，视力已经发育好的学生。同时，现在电子产品相对普及，我们的系统颜色相对单一，没有很多炫酷的动画，因为越是好的动态画面就越是伤眼睛，我们主要为了学习效果，所以画面相对清爽简洁。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-4",
    isHot: false,
    title: "从你们的学情报告来看，我的孩子不应该只考这点分数",
    answer: `<p>我们系统的学情报告主要反映的是学生的知识点掌握情况，知识点掌握情况好不一定考试成绩就好。因为考试受到其他的很多因素影响，孩子做题速度偏慢，后面后很多大题没有花时间去做题，导致丢分。很多情况下审题不清，演算错误，也会导致丢分，所以很多情况下，学生知识点掌握了，但是，并不意味着考试就能考出好成绩，还需要培养学生的学习习惯。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-6",
    isHot: false,
    title: "小孩子一直看着电脑学习，会不会太累啊？",
    answer: `<p>对孩子而言，长时间盯着电脑，是比较累的，不过我们也是和学校里一样的，是有中途休息的，往往我们的学生学完一节，我们老师就会安排学生休息一会儿，环节疲劳和保护视力。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-7",
    isHot: false,
    title: "孩子在学习过程中有问题，可以向系统里的老师提问吗？",
    answer: `<p>学生在学习过程中有疑问，暂时不能向系统里提问，但是可以和我们的老师进行提问，我们的老师也会帮助学生来进行解答的。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-8",
    isHot: false,
    title: "我的孩子上什么学校好？",
    answer: `<p>学习是一个很重要的过程，小学升初中很重要，因为好的初中就能培养出更多的学生进入重点高中，重点高中进入重点大学的几率也大。后期孩子在社会上的层次和地位都是和我们选择的学校有很重要的因素，所以学习需要从小学开始就需要努力，不能说小学不重要，初中努力还来得及。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-9",
    isHot: false,
    title: "我的孩子学习成绩很好，有必要再来学习吗？",
    answer: `<p>孩子学习成绩的好坏是相对的，可能孩子在本校成绩还可以，在其他学校成就就不一定了，而且孩子自认为学习成绩很好，没必要学习，学习最怕的就是骄傲和自满，出现这种情况，一定要注意。针对学习相对较差的是进行补课，成绩好的学员要进行培优，一定要给孩子养成不断学习的习惯，学习最忌骄傲自满和懒惰。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-10",
    isHot: false,
    title: "我孩子就不适合学习，算了，反正也学不好",
    answer: `<p>请问家长您希望孩子能够学习成绩有所提升吗？孩子她真的就甘愿作为一名差生吗？我想应该不会吧？您说的孩子不适合学习是错误的，没有哪个学员是不适合学习的，只是没有找到正确的学习方法以及没有人去引导他去学习罢了，久而久之，耳濡目染，孩子自然会失去信心，所以 您可以带孩子过来体验一下 这种新模式，很有可能适合他呢。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-11",
    isHot: false,
    title: "孩子认真学习学得很快，就是容易跑神，总想着玩",
    answer: `<p>孩子爱玩是通病，毕竟现在就是爱玩的年龄，但是比玩更重要的事情就是学习，孩子容易跑神往往周围有事物在吸引他，或者是没有休息好。建议让孩子好好休息，并且给孩子提供一个安静的环境进行学习，同时保证孩子学习桌上没有分散注意力的物品。还需要给孩子设定目标，完成目标有奖励。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-12",
    isHot: false,
    title: "为什么要这么长时间去测评，之前微信不是测评过了吗？",
    answer: `<p>之前微信的测评是相对简易的测评。我们这次测评就像人身体不舒服，但是不知道哪里有问题，需要去大医院进行系统的检查一样。追根溯源去找到学生现在的薄弱点，针对薄弱点，我们进行针对性地学习，来提升学生的成绩。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-13",
    isHot: false,
    title: "孩子偏科严重怎么办？",
    answer: `<p>学生有偏科是一种非常正常现象，但是偏科也说明一个问题，孩子的学习能力是没有问题的，可能是其他方式导致某个学科存在一些问题，这个需要我们去深入了解，是因为一次失败有了挫败感，没有信心，还是就是不喜欢教学的老师，还是其他原因。最重要的就是不要让孩子否定自己，一定要和孩子说，学习道路大致上至大庭相径，只是方法和想法需要进行改变。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-14",
    isHot: false,
    title: "孩子考试没有考到理想中的成绩，学习热情降低，怎么办？",
    answer: `<p>一般情况下，只有相对要学习的，相对懂事的孩子会出现这种情况，因为考试不好，导致产生了挫败感。这个时候一定要给学生鼓励，并且说明，他是个有上进心的孩子，所以现在要做的事情不是一味地苦恼，而是分析一下这次考试的情况，找到问题点，下次改进。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-15",
    isHot: false,
    title: "孩子不喜欢学校的数学老师，就对数学很排斥，怎么办？",
    answer: `<p>造成学生偏科的很大一部分原因就是学生和老师不对眼，这个时候需要和学生深入沟通，了解不喜欢老师的原因，然后制定个性化的学习方案，寻找适合该学员的学习方式来帮助学员提升成绩，学校里很多的老师是为了完成教学任务，有的时候不会针对个别学生进行关怀，所以，机构要做的不仅仅是分析学生学习情况，还有关怀，做学生的朋友。</p>`,
  },
  {
    typeId: "3",
    classifId: "3-4",
    id: "3-4-16",
    isHot: false,
    title: "孩子平时做作业喜欢磨洋工，有拖延症，有什么办法吗？",
    answer: `<p>孩子有拖延症，是一个比较坏的学习习惯，一般有几种情况，一是根本不会，但是也不说，所以作为家长，老师需要去询问学生知识点是不是没有掌握。二是注意力分散，这时候需要提醒孩子，把孩子的神拉回来，并且给孩子提供一个安静的环境进行学习，同时保证孩子学习桌上没有分散注意力的物品。三是孩子没有学习热情，需要给孩子设定目标，完成目标有奖励。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-1",
    isHot: true,
    title: "资料库在哪里？",
    answer: `<p>百度网盘搜索群号9833599420进入资料，点击群文件，查看最新资料文件夹打开即可。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-2",
    isHot: false,
    title: "资料库中的文件怎么打不开？",
    answer: `<p>图片或者物料类文件资料库中同一名称文件将会上传两次，一份为PGN/JPG看稿是用来查看的，而对应文件名称的PSD/AI文件则为制作稿可直接发送给广告公司制作。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-3",
    isHot: true,
    title: "广告类资料在哪里找，需要找谁？",
    answer: `<p>晓果智学所有相关物料、文档、图片、制作稿等直接进入晓果资料库百度网盘分享群下载，每周将会进行更新，可通过晓果资料库目录进行快速查找。
    晓果资料库百度网盘分享群群号：9833599420</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-4",
    isHot: false,
    title: "百度网盘下载怎么这么慢？",
    answer: `<p>网盘内资料近20个G，如果没有百度网盘会员或网络较慢可能两天不一定能把真个资料库下载完。因此建议大家可在百度网盘晓果智学资料群中进入【群文件】或【文件库】中找到晓果资料库文件夹进行预览查找后选择所需要的使用的文件或资料转存到自己的网盘再进行下载。
    因网盘资料较多且更新周期较快（每周更新一次以上）因此建议只下载所需要的部分，防止资料版本较早的问题。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-5",
    isHot: false,
    title: "资料这么多怎么进行查找？",
    answer: `<p>两种快捷方式：</p>
        <p>1）未转存到自己的网盘：在百度网盘资料群中找到【晓果资料库】文件夹，打开文件夹后找到【12、晓果资料库目录】WORD文档，使用WORD文档中【开始】菜单中的【查找替换】功能输入关键词进行查找即可，根据目录文档中显示的标题号进入资料库进行查找对应文件。
        如不会使用可查看【13、晓果智学百度网盘使用及资料快速检索介绍2020.8.24-MP4】视频演示进行学习。</p>
        <p>2）晓果资料库已转存至自己的网盘：点击进入晓果资料库后点击网盘右上角输入框（输入框中显示：搜索我的网盘文件）在输入框中输入文件或资料关键词点击搜索按钮即可在资料库中进行快速查找。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-6",
    isHot: false,
    title: "用电脑打开的网盘怎么把资料保存和下载以及分享？",
    answer: `<p>如需保存、下载或分享资料首先需要选择对应文件点击转存至你的网盘后，再打开你的网盘进行下载和分享操作。
    电脑转存：勾选对应文件后点击保存按钮或下载按钮即可。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-7",
    isHot: false,
    title: "用手机登录的网盘怎么把资料保存和下载以及分享？",
    answer: `<p>如需保存、下载或分享资料首先需要选择对应文件点击转存至你的网盘后，再打开你的网盘进行下载和分享操作。
    手机转存：点击对应文件后方○按钮后点击保存至我的网盘，即可转存。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-8",
    isHot: false,
    title: "有些资料是PDF版本的不能修改怎么弄？",
    answer: `<p>所有公司性文件及产品介绍类不可修改的文件统一使用PDF文件，加盟校区会使用到的表格、文件、PPT等统一为原格式，可直接进行修改调整。
    如各代理商需要制作手册或其他资料的源文件进行修改和给广告公司制作可以文字说明的形式将需求反馈至加盟服务群由总部对接给予资料源文件。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-9",
    isHot: false,
    title: "怎么把文件发给广告公司制作？",
    answer: `<p>装修类物料可直接将网盘中的AI、PSD等格式的制作稿分享转发给广告公司或在广告公司电脑上登录你的网盘直接下载到广告公司的电脑上。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-10",
    isHot: false,
    title: "海报怎么保存到手机上面发朋友圈？",
    answer: `<p>海报类广宣物料图片不会进行修改只会进行更新，因此可直接将已经上传至网盘分享的海报转存至你的网盘。
    再通过电脑或手机直接下载分享即可，所有已分享图片基本都有配文，如需配文可添加余老师微信，在余老师朋友圈中找到对应图片的配文复制粘贴即可。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-11",
    isHot: false,
    title: "规章制度类文档怎么进行修改？",
    answer: `<p>文档及表格上传的都是源文件，如果不会电脑操作需要修改可参照以下三种方案：</p>
        <p>1）百度搜索修改方法。</p><p>2）校区内找一名会基本办公软件的老师帮助修改。</p><p>3）去广告公司让他们进行修改。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-12",
    isHot: false,
    title: "118、绩效考核、评估表这一类的表格怎么修改和制作？",
    answer: `<p> 文档及表格上传的都是源文件， 如果不会电脑操作需要修改可参照以下三种方案：</p>
    <p> 1） 百度搜索修改方法。</p>
    <p> 2） 校区内找一名会基本办公软件的老师帮助修改。</p>
    <p> 3） 去广告公司让他们进行修改。</p>
    <p> 修改完成后直接发送给广告公司制作， 如自身有打印机的可连接打印机直接打印即可。 </p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-13",
    isHot: false,
    title: "文档和表格上只有晓果的logo我要怎么添加我的logo进去？",
    answer: `<p>1）文档添加logo方法：</p>
        <p>点击文档上方菜单栏【插入】选项，点击【页眉和页脚】，文档将自动跳转至页眉部分，点击图片进入本地图片将所需要的添加的logo上传，上传后图片右侧显示一列选项，选择【布局选项】功能，文字环绕选项中选择【浮于文字上方】，再根据页面情况将鼠标放在图片外围8个圆圈的任意一点进行放大或缩小到合适尺寸后直接拖动图片调整位置即可。</p>
        <p>2）表格添加logo方法：</p>
        <p>点击表格上方菜单栏【插入】选项，点击【页眉和页脚】，页眉/页脚栏中点击【自定义页眉】，选择logo所需要的放的地方（左或中或右）然后点击上方图标中倒数第二个【插入图片】图标，选择对应图片后在页面栏将显示【&〖图片〗】字样后点击上方图标最后一个【设置图片格式】选项调整图片大小、角度、比例或图片裁剪即可，设置完成后点击确定，点击表格左上方【文件】按钮选择【打印】中的【打印预览】选项查看logo是否合适是否遮住正文，如有问题再进入页眉页脚中进行调整大小和比例。</p>
        <p>在打印中点击【页边距】选项可通过移动最上方一条横线调整logo距离页面顶端的距离。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-1",
    id: "4-1-14",
    isHot: false,
    title: "文件那么多种格式分别是代表干什么用的？应用场景是怎样的？怎么打开？",
    answer: `<p>资料库中常见的文件格式有WORD、EXCEL、PPT、PDF、JPG、PNG、PSD、AI等格式文件。</p>
        <p>Word:为文字文档，应用为常用的文字性文件，可直接使用WPS或电脑自带的Microsoft Office打开进行修改和查阅。</p>
        <p>Excel:为表格文档，应用为常用的表格类文件，可直接使用WPS或电脑自带的Microsoft Office打开进行修改和查阅。</p>
        <p>PPT:为幻灯片文档，应用为常用的演讲、上课或开会使用的幻灯片文件，可直接使用WPS或电脑自带的Microsoft Office打开进行修改和查阅。</p>
        <p>PDF:为不可修改的查阅文档，应用为由Word、Excel、PPT等格式文件转化出来的只可查看不可修改的只能用来查阅类似于图片的文件，可直接使用WPS或电脑自带的Microsoft Office打开进行修改和查阅。</p>
        <p>JPG：或JPEG是高清图片或由其他软件经过压缩的缩略图或预览图，平常手机拍照的图片也是这种格式。应用为设计软件不会使用的情况下总部将会把设计图压缩成缩略图同时上传至网盘，只需要根据缩略图查看内容即可，如需制作则将缩略图同名不同格式的AI或PSD格式文件直接发送给广告公司即可。</p>
        <p>PNG：也是一种经过压缩的缩略图或预览图，所占内存更小、色彩更逼真，还支持透明效果。应用场景同JPG但不同的是大部分logo以PNG的格式导出后以透明效果呈现插入任何文件中只显示logo不显示底色。</p>
        <p>PSD：是Photoshop图形设计软件的专用格式。应用场景为页面设计或美化，需要专用软件打开，一般作为源文件制作稿使用，加盟校区不需要打开直接发送给广告公司即可。</p>
        <p>AI：是Adobe Illustrator绘图程序软件的专用格式，是一种矢量图形文件格式。应用场景为绘制图形或美化，需要专用软件打开，一般作为源文件制作稿使用，加盟校区不需要打开直接发送给广告公司即可。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-2",
    id: "4-2-1",
    isHot: true,
    title: "门头装修可以帮我们设计吗？",
    answer: `<p>门头不需要设计，直接参照【晓果资料库】→【11、装修物料】→【11.2旗舰店装修方案】→【11.2.1门头图】中两种门头设计方案选择一种适合自身校区的效果图即可。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-2",
    id: "4-2-2",
    isHot: false,
    title: "门头制作的尺寸可以帮我们修改一下吗？",
    answer: `<p>尺寸不需要总部进行修改，制作时直接将效果图和门头尺寸给到广告公司即可。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-2",
    id: "4-2-3",
    isHot: false,
    title: "好几种门头方案我应该用哪一种？",
    answer: `<p>选择适合自身机构调性的门头设计方案即可，原则上是越美观越好，但更应该从校区实际出发，以经济基础为前提。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-2",
    id: "4-2-4",
    isHot: true,
    title: "海报、易拉宝图片这么多我要用哪个合适？",
    answer: `<p>可根据需要进行选择，没有强制规定必须使用哪一张，如果校区面积够大、资金雄厚也可选择全部制作，如果面积有限广告费用有限选择性制作需要的即可。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-2",
    id: "4-2-5",
    isHot: false,
    title: "上墙物料这么多我应该用哪一些，总部有没有推荐的？",
    answer: `<p>可根据需要进行选择，没有强制规定必须使用哪一些，如果校区面积够大、资金雄厚也可选择全部制作，如果面积有限广告费用有限选择性制作需要的即可。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-2",
    id: "4-2-6",
    isHot: false,
    title: "我想把证书牌做成KT板放到墙上，有源文件吗？",
    answer: `<p>所有证书的预览图和制作稿均已上传，可通过【晓果资料库】→【11、装修物料】→【11.8企业资质证书】途径进行选择和下载。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-2",
    id: "4-2-7",
    isHot: false,
    title: "装修有没有标准和规范？",
    answer: `<p>整体硬装上截止2020年9月没有强制要求终端统一化标准，各校区可根据自身情况进行装修、调整。但logo及物料的使用是必须按照标准进行执行的。
    详细要求见【晓果资料库】→【11、装修物料】→【11.2旗舰店装修方案】→【11.2.7晓果智学装修标准】部分进行选择和下载。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-2",
    id: "4-2-8",
    isHot: false,
    title: "我想做一些纸杯和一些礼品有没有制作稿和logo呀？",
    answer: `<p>有的。</p>
        <p>1）参照【晓果资料库】→【11、装修物料】→【11.5VI手册物料】进行选择或下载。</p>
        <p>2）参照【晓果资料库】→【11、装修物料】→【11.6晓果智学VI手册】进行选择或下载。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-1",
    isHot: false,
    title: "我要怎么进行收费，有没有收费模板？",
    answer: `<p>有的。</p>
    <p>可参照【晓果资料库】→【11、装修物料】→【11.9收费标准模板】进行选择或下载，根据当地经济发展水平、当地教育机构收费及自身运营情况等综合因素进行调整即可。</p>
    `,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-2",
    isHot: true,
    title: "想要开个家长会不知道怎么开总部有没有做好的课件呀？",
    answer: `<p>有的。</p>
        <p>可参照【晓果资料库】→【9、营销培训】→【9.3培训内容】→【9.3.3晓果智学家长会PPT2.0】进行下载或修改。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-3",
    isHot: false,
    title: "我需要去外面去招商/招生总部有没有系统介绍或者产品介绍的资料？",
    answer: `<p>有的。</p>
        <p>可参照【晓果资料库】→【1、管理物料】中根据需要下载对应PPT或手册文件即可。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-4",
    isHot: false,
    title: "我们需要老师阶段总结汇报教学情况，总部有统一的PPT模板吗？",
    answer: `<p>有的。</p>
        <p>可参照【晓果资料库】→【1、管理物料】→【1.8晓果智学官方模板PPT】进行PPT书写即可。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-5",
    isHot: false,
    title: "老师上课要怎么激励和鼓励学生学习？",
    answer: `<p>教师可借鉴奖惩方案进行调整和整合。</p>
        <p>路径：【晓果资料库】→【2.教师物料】→【2.5晓果智学奖惩方案】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-6",
    isHot: false,
    title: "我们出去招生要怎么介绍晓果智学，招生思路是怎样的？",
    answer: `<p>路径：【晓果资料库】→【2.教师物料】→【2.3晓果智学招生思路】&【2.4如何介绍晓果智学】同时可参考【1、管理物料】中【1.10晓果智学项目介绍手册】或【1.11晓果智学宣传册】进行讲解。</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-7",
    isHot: false,
    title: "我要怎么招聘老师？面试要说些什么？",
    answer: `<p>可参考资料库中的面试提纲和面试评估表。</p>
        <p>路径：【晓果资料库】→【4、招聘物料】→【4.3晓果智学面试评估表】&【4.4晓果智学面试提纲】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-8",
    isHot: false,
    title: "制定怎样的教师KPI绩效考核和制度合适？",
    answer: `<p>可参考资料库中KPI考核表和教师管理制度。</p>
        <p>路径：【晓果资料库】→【9、营销培训】→【9.2各个岗位KPI考核表】</p>
        <p>路径：【晓果资料库】→【10.教师培训】→【10.1教师管理制度】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-9",
    isHot: false,
    title: "机构老师的薪资要怎么设置，有没有模板？",
    answer: `<p>可参考资料库中薪酬架构设定。</p>
        <p>路径：【晓果资料库】→【9、营销培训】→【9.1薪酬架构设定】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-10",
    isHot: false,
    title: "新校区要怎么考核机构的老师？",
    answer: `<p>可参考资料库中KPI考核表和教师管理制度。</p>
        <p>路径：【晓果资料库】→【9、营销培训】→【9.2各个岗位KPI考核表】</p>
        <p>路径：【晓果资料库】→【10.教师培训】→【10.1教师管理制度】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-11",
    isHot: false,
    title: "我要怎么进行招生？",
    answer: `<p>可参考【晓果资料库】→【6、开业运营】→【6.3招生广宣】→【6.3.1招生方案】→【6.3.1.1晓果智学招生思路】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-12",
    isHot: false,
    title: "我已经招到生了，有没有统一的学生入学合同和规章制度以及学员手册？",
    answer: `<p>有的。</p>
        <p>路径：【晓果资料库】→【3、学员物料】→【3.2晓果学院规章】&【3.3晓果智学学员入学合同】&【3.4晓果智学学员手册】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-13",
    isHot: false,
    title: "我马上要开业运营了要怎么进行培训？",
    answer: `<p>可通过多种管理物料、教师培训、营销培训等多个文件进行讲解。</p>
        <p> 如【晓果资料库】→【10、教师物料】→【10.2体验课流程】、【晓果资料库】→【9、营销培训】→【9.3培训内容】等</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-14",
    isHot: false,
    title: "我已经开始运营了想做微信公众号和抖音推广要怎么做？",
    answer: `<p>可参考线上线下推广宣传的培训及文案。</p>
        <p>路径：【晓果资料库】→【9、营销培训】→【9.7线上推广宣传】及【9.7.3线上推广文案】等资料</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-15",
    isHot: false,
    title:
      "马上到招生旺季了，以前没有做过教育，总部有没有比较好的招生方案和宣传单？",
    answer: `<p>有的。</p>
        <p>路径：【晓果资料库】→【6、开业运营】→【6.3招生广宣】→【6.3.1招生方案】</p>
        <p>路径：【晓果资料库】→【6、开业运营】→【6.3招生广宣】→【6.3.2广告】→【6.3.2.3地推传单】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-16",
    isHot: false,
    title: "我想出去招商但是不了解人工智能教育行业，总部能给我进行培训吗？",
    answer: `<p>不需要培训的，直接从网盘下载行业报告做大致的了解就行了。</p>
        <p>路径：【晓果资料库】→【9、营销培训】→【9.8自适应行业报告】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-17",
    isHot: true,
    title: "新招了课程顾问但是以前没有做过有没有工作流程和邀约话术呢？",
    answer: `<p>有的。</p>
        <p>路径：【晓果资料库】→【9、营销培训】→【9.5销售话术】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-18",
    isHot: false,
    title: "我们设置什么样的课程体系和收费规则合适？",
    answer: `<p>可以参考课程体系与开班收费PPT。</p>
        <p>路径：【晓果资料库】→【9、营销培训】→【9.4销售工具】→【9.4.1课程体系与开班收费-PPT】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-19",
    isHot: false,
    title: "有没有选址的指南呢？",
    answer: `<p>有的。</p>
        <p>路径：【晓果资料库】→【6、开业营业】→【6.4选址指南】</p>`,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-20",
    isHot: false,
    title: "晓果给的资料库如何使用？",
    answer: `<p>下载百度网盘后，输入账号密码，查看“晓果智学百度网盘使用及资料快速检索介绍”视频，按照视频流程操作。</p>
      `,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-21",
    isHot: false,
    title: "我可以把自己的资料上传到资料库里吗？",
    answer: `<p>网盘里的资料库是提供给各机构参考和学习的，不得私自篡改，删除及上传文档至资料库。</p>
        `,
  },
  {
    typeId: "4",
    classifId: "4-3",
    id: "4-3-22",
    isHot: false,
    title: "关于校区安全运营，有什么指导吗？",
    answer: `<p>有的。</p>
        <p>路径：【晓果资料库】→【6、开业营业】→【6.5运营安全管理】</p>`,
  },
];
export { menus, menus2, problems };
